/* New CSS */
body {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
}

.contact-section {
    flex-grow: 1;
    background-image: url('../back.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}


.form-container {
    background: rgba(255, 255, 255, 0.5);
    
    backdrop-filter: blur(5px);
    border-radius: 25px;
    padding: 50px;
    width: 50%;
    /* margin-top: 50px; */
}

.contact-title {
    font-size: 50px;
    background: linear-gradient(45deg, red, orange, purple, blue, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 50px;
}

.contact-form label {
    font-size: 20px;
    margin-top: 50px;
    display: block;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
}

.contact-form textarea {
    height: 100px;
}

.submit-button {
    margin-top: 70px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    color: #fff;
    background-color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 18px;
}

.submit-button:hover {
    background-color: #ff6347;
}


