body {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  background-color: #121212;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* CHAT BUBBLES */
.user, .bot {
  border-radius: 20px;
  margin: 5px 0;
  padding: 8px 16px;
}

.user {
  background-color: #292332;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  align-self: flex-end;
}

.bot {
  background-color: #453f4d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
}

/* CONTAINER FOR CHAT */
#messages {
  
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin-bottom: 50px;
  overflow-y: auto;
  height:  calc(100vh - 160px);
  flex-grow: 1;
 
}

#messages .bot:last-child,
#messages .user:last-child {

  
    margin-bottom: 55px;  /* Adding 50px space; plus existing 5px margin */
}
.messages-container {
  
  height: calc(70vh - 100px - 100px);  /* This deducts the avatar's height and an estimated height for the input area. Adjust the second value as needed */
  overflow-y: auto;
  padding: 10px 20px;
  margin-bottom: 50px;
}

/* Scrollbar styles for Webkit browsers */
#messages::-webkit-scrollbar {
  width: 10px;  /* Width of the scrollbar */
}

#messages::-webkit-scrollbar-track {
  background: #2a2a2a;  /* Color of the track */
}

#messages::-webkit-scrollbar-thumb {
  background: #4a4a4a;  /* Color of the thumb */
  border-radius: 5px;  /* Roundness of the thumb */
}

#messages::-webkit-scrollbar-thumb:hover {
  background: #626262;  /* Color of the thumb when hovered */
}

/* Scrollbar styles for Firefox */
#messages {
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #2a2a2a;
}

/* Scrollbar styles for Webkit browsers */
.messages-container::-webkit-scrollbar {
  width: 10px;  /* Width of the scrollbar */
}

.messages-container::-webkit-scrollbar-track {
  background: #2a2a2a;  /* Color of the track */
}

.messages-container::-webkit-scrollbar-thumb {
  background: #4a4a4a;  /* Color of the thumb */
  border-radius: 5px;  /* Roundness of the thumb */
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: #626262;  /* Color of the thumb when hovered */
}

/* Scrollbar styles for Firefox */
.messages-container {
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #2a2a2a;
}



/* INPUT AREA */
.input-container {
  position: sticky; 
  bottom: 30px; 
  background-color: #121212;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.message-submit-container {
  width: 100%; 
  max-width: 1000px; 
  display: flex;
  
   
   align-items: center;  
}

.message-input {
  width: 100%; 
  padding: 10px;
  border-radius: 20px;
  border: none;
}

.button-container {
  margin-bottom: 25px;
  margin-top: 25px;
}

.click {
  position: relative;
  display: inline-block; /* To ensure width and height applies */
  padding: 10px 20px;
  border-radius: 50px;
 
  font-size: 15px;
  background-color: #292332;
  color: white;
  border: none;
  cursor: pointer;
  outline: none; /* To remove default focus style */
  overflow: hidden; /* To contain pseudo-element within button */
  animation: btnPulse 2s infinite;
  z-index: 1;
}

@keyframes btnPulse {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 166, 0, 0.869);
  }
  50% {
      box-shadow: 0 0 40px 10px rgba(255, 165, 0, 0.5);
  }
  
  100% {
      box-shadow: 0 0 0 0 rgba(255, 165, 0, 0);
  }
}

.click::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 165, 0, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s, opacity 0.5s;
  z-index: -1;
  opacity: 0;
}

.click:active::before {
  transform: translate(-50%, -50%) scale(3);
  opacity: 1;
  transition: transform 0.2s, opacity 0.2s;
}

/* Optional: hover effect */
.click:hover {
  background-color: #3a3342; 
}



.donate-link {
  color: #ff9500;  
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
}

.donate-link:hover {
  color: #85eb1e; 
}

.submitt-button {
  padding: 10px;
  background-color: #ffd20b; 
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.submitt-button:hover {
  background-color: #45a049; 
  transform: scale(1.1);
}

.submitt-button .button-icon {
  width: 20px;
  height: auto;
}

/* .loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
} */


/* LottieLoader.css */
.lottie-container {
  display: flex;
  justify-content: center;
  align-items: center;
   /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other items */
}






/* at 300px to 400px i want the height of input container to be 300px , .message-input :- width to be 80%, with padding 30px
.message-submit-container :- no margin left i want it to be removed margin left should be 0. submit-button:- padding :- 25px 
 .click :- padding:- 31px 108px .user :- padding :- 15px 49px  */

 

 @media only screen and (min-width: 300px) and (max-width: 500px) 

 {

  .input-container {
    height: 300px;
  }

  .message-input {
    width: 80%;
    padding: 30px;
  }

  .message-submit-container {
    margin-left: 0;
    transform: translateY(-14px);
  }

  .submitt-button {
    padding: 25px;
  }

  .click {
    padding: 31px 108px;
  }

  .user {
    padding: 15px 49px;
  }

}

@media (max-width: 600px) {
  .input-container {
    height: 200px;
    padding-bottom: 30px;
  }

  .message-input {
    width: 80%;
    padding: 20px;
  }

  .message-submit-container {
    margin-left: 0;
  }

  .submitt-button {
    padding: 14px;
  }

  .click {
    padding: 12px 49px;
    margin-top: 20px;
    transform: translateY(-50%);
  }

  .user {
    padding: 15px 100px;
  }
}