.privacyP {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0.1em;
    word-spacing: 0.2em;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 30px;
    background-color: #ffffff;
    color: black;
}
