.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    width: 60%;
    background: #77599e;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    color: white;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.donate {
    color: orange;
}