.footer {
    /* Remove or comment out: position: fixed; */
    
    background-color: #121212; 
    color: #ffffff;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}


.footer-content {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px; 
}

.links {
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    justify-content: space-between;
    width: 600px; 
}

.links a {
    color: #bbbbbb;  /* Lighter grey for links to stand out a bit */
    margin-top: 30px;
    text-decoration: none;
    width: 22%; 
    transition: color 0.3s ease; /* Smooth transition for link hover */
}

.links a:hover {
    color: #ffffff;  /* White on hover for a subtle effect */
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .links {
        flex-direction: column;
        width: 100%; 
        align-items: center;
    }

    .links a {
        width: 80%; 
        text-align: center;
        margin: 10px 0;  /* Adjusted margin for better mobile spacing */
    }
}
